import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule, MatSidenavModule, MatToolbarModule, MatIconModule, MatListModule, MatButtonModule, MatTableModule,
   MatSortModule, MatFormFieldModule, MatInputModule, MatPaginatorModule, MatCardModule, MatProgressBarModule,
   MatCheckboxModule, MatProgressSpinnerModule, MatDatepickerModule, MatNativeDateModule, 
   MatRadioModule, MatDialogModule, MatSelectModule, MatStepperModule } from '@angular/material';
import { MatSelectSearchModule } from '../shared/mat-select-search/mat-select-search.module';

   


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatTabsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatCardModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatDialogModule,
    MatSelectModule,
    MatStepperModule,
    MatSelectSearchModule
  ],
  exports: [
    MatTabsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatCardModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatDialogModule,
    MatSelectModule,
    MatStepperModule,
    MatSelectSearchModule
  ]
})
export class MaterialModule { }
