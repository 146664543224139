import { Component, OnInit, Inject } from '@angular/core';
import { PersonneService } from '../../../services/personne/personne.service';
import { ErrorHandlerService } from '../../error-handler.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Library } from '../../../_interface/library.model';
import { LibrariesService } from '../../../services/libraries/libraries.service';
import { ResponsableDto } from '../../../_interface/responsableDto.model';
import { Personne } from '../../../_interface/personne.model';
import { CodesDto } from '../../../_interface/codesDto.model';
import { Codes985 } from 'src/app/_interface/codes985.model';
import { Codes972 } from 'src/app/_interface/codes972.model';
import { Codes905 } from 'src/app/_interface/codes905.model';


@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  private library: Library;
  private dialogConfig;
  
  constructor(
    private librariesService: LibrariesService,
    private personneService: PersonneService,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private errorService: ErrorHandlerService,
    @Inject(MAT_DIALOG_DATA) 
    public data: any) {
}

  ngOnInit() {

    this.dialogConfig = {
      height: '400px',
      width: '400px',
      disableClose: true,
      data: { }
    }
  }


  public deleteItem = ( ) => {

    if(this.data['personnes'] !== null && this.data['personnes']!== undefined 
       && this.data['apiUrl'] !== null && this.data['apiUrl']!== undefined){
      this.personneService.createPersonne(this.data['apiUrl'], this.data['personnes'])
        .subscribe(res => {
            if(res != null){
              this.library = res as Library;
              this.librariesService.setPassObject(this.library);
            }
            
        },
        (error => {
          this.errorService.dialogConfig = this.dialogConfig;
          this.errorService.handleError(error);
        })
      )
  }

  let responsableDto = this.data['respDto'] as ResponsableDto;
  if(responsableDto !== null && responsableDto !== undefined 
    && this.data['apiUrl'] !== null && this.data['apiUrl']!== undefined){
    let personne         = <Personne>{} ;
    personne.id          = responsableDto.idResp;
    personne.nom         = responsableDto.nomResp;
    personne.email       = responsableDto.emailResp;
    personne.telephone   = responsableDto.teleResp;
    personne.remarques   = responsableDto.remaResp;
    this.personneService.createPersonne(this.data['apiUrl'], personne).subscribe(res => {
      if(res != null){
        this.library = res as Library;
        this.librariesService.setPassObject(this.library);
      }
      
    },(error => {
        this.errorService.dialogConfig = this.dialogConfig;
        this.errorService.handleError(error);
      })
    )
  }

  var typeCode: string;
  var objCode:any;
  let codesDto = this.data['codesDto'] as CodesDto;
  if(codesDto !== null && codesDto !== undefined 
    && this.data['apiUrl'] !== null && this.data['apiUrl']!== undefined){
      typeCode = this.data['typeCode'];
      if(typeCode == '985' ){
         objCode = <Codes985>{} ;
      }
      if(typeCode == '972' ){
        objCode = <Codes972>{} ;
      }
      if(typeCode == '905' ){
        objCode = <Codes905>{} ;
      }
      objCode.code         = codesDto.codeCode;
      objCode.description  = codesDto.descriptionCode;
      this.personneService.createPersonne(this.data['apiUrl'], objCode).subscribe(res => {
        if(res != null){
          this.library = res as Library;
          this.librariesService.setPassObject(this.library);
        }
        
      },(error => {
          this.errorService.dialogConfig = this.dialogConfig;
          this.errorService.handleError(error);
        })
      )
  }
}

onNoClick(): void {
  this.dialogRef.close();
}

}
