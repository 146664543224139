import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from './shared/error-handler.service';
import { User } from './_interface/user.model';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  title = 'angelib';
  public user : User;


  constructor(private loginService: LoginService,
    private errorService: ErrorHandlerService) { }

  ngOnInit() {
    this.getUserLogged();
  }

  public getUserLogged = () => {
    this.loginService.getData(`api/findUser`).subscribe(res => {
      if(res != null){
          this.user = res as User;
          this.loginService.setPassObject(this.user);
          if(this.user !== null && this.user.group !== 'operators'){
            this.errorService.accesDenied();
          }
      }
     
    },
    (error) => {
      this.errorService.handleError(error);
    })
  }
  
}
