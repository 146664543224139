import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Codes985 } from '../../../_interface/codes985.model';
import { LibrariesService } from '../../../services/libraries/libraries.service';
import { Library } from 'src/app/_interface/library.model';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { Location} from '@angular/common';
import { ErrorHandlerService } from '../../error-handler.service';
import { Codes985Service } from '../../../services/codes985/codes985.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.css']
})
export class AddDialogComponent implements OnInit {

  
  private unSubscri: Subscription;
  private library:Library;
  private codes985 = <Codes985>{} ;
  public  code985Form: FormGroup;
  private dialogConfig;
  private stopSubscriber: boolean;
  public  buttonSave:string;
  public  title:string;
  public code972:boolean    = false;

  constructor(private librariesService: LibrariesService,
              private codes985Service: Codes985Service,
              private dialogRef: MatDialogRef<AddDialogComponent>,
              //private dialog: MatDialog,
              private errorService: ErrorHandlerService,
              private location: Location,
              @Inject(MAT_DIALOG_DATA) public data: any) { 
              }

  ngOnInit() {
    
    var codedisabled: boolean;
    if(this.data['codes'] !== null && this.data['codes'] !== undefined){
      codedisabled = true;
      this.buttonSave = 'Modifier';
      this.title ='Modifier le code '+this.data['typeCode'];
    }else{
      codedisabled = false;
      this.buttonSave = 'Ajouter';
      this.title ='Ajouter un nouveau code '+this.data['typeCode'];
    }

    if(this.data['typeCode'] === '972'){
      this.code972 = true;
      this.code985Form = new FormGroup({ 
      code: new FormControl({ value: '', disabled: codedisabled },[Validators.required]),
      //description: new FormControl('', [Validators.required])
    })}else{
      this.code972 = false;
        this.code985Form = new FormGroup({ 
        code: new FormControl({ value: '', disabled: codedisabled },[Validators.required]),
        description: new FormControl('', [Validators.required])
      })
    }

  if(this.data['codes'] !== null && this.data['codes']!== undefined){
    this.code985Form.patchValue(this.data['codes']);
  }
 
  this.dialogConfig = {
    height: '400px',
    width: '400px',
    disableClose: true,
    data: { }
  }

}

ngOnDestroy() {
  if(this.unSubscri != null){
    this.unSubscri.unsubscribe();
  }
  
}

public createCodes985 = (code985Form) => {
  this.stopSubscriber = false;
  if (this.code985Form.valid) {
    this.unSubscri = this.librariesService.passObject.subscribe(res => {
          if(res !== null){
            this.library  = res as Library;
            if(this.data['codes'] !== null && this.data['codes']!== undefined){
              if(!this.stopSubscriber){
                this.executeUpdateCodes985(code985Form.getRawValue());
              }
              
            }else{
              if(!this.stopSubscriber){
                this.executeCreateCodes985(code985Form.getRawValue());
              }
          }
          if(this.unSubscri != null){
            this.unSubscri.unsubscribe();
          }
         
        } 
    })
  }
}

private executeCreateCodes985 = (code985Form) => {

  this.codes985.code        = this.code985Form.value.code;
  //this.codes985.description = this.code985Form.value.description;

  if(this.data['typeCode'] == '972' ){
    this.codes985.description  = '-';
  }else{
    this.codes985.description = this.code985Form.value.description;
  }

  var apiUrl :string;
  if(this.data['typeCode'] == '985' ){
     apiUrl = `api/createCodes985/${this.library.code}`;
  }
  if(this.data['typeCode'] == '972' ){
    apiUrl = `api/createCodes972/${this.library.code}`;
 }
 if(this.data['typeCode'] == '905' ){
  apiUrl = `api/createCodes905/${this.library.code}`;
}

  this.codes985Service.createCodes985(apiUrl, this.codes985)
    .subscribe(res => {
        if(res != null){
          this.library = res as Library;
          this.stopSubscriber = true;
          this.librariesService.setPassObject(this.library);
        }
        
    },
    (error => {
      this.librariesService.unsuscribe();
      this.errorService.dialogConfig = this.dialogConfig;
      this.errorService.handleError(error);
    })
  )
}

private executeUpdateCodes985 = (code985Form) => {

  
  this.codes985.code        = code985Form.code;
  //this.codes985.description = this.code985Form.value.description;

  if(this.data['typeCode'] == '972' ){
    this.codes985.description  = '-';
  }else{
    this.codes985.description = code985Form.description;
  }

  var apiUrl :string;
  if(this.data['typeCode'] == '985' ){
    apiUrl = `api/updateCodes985/${this.library.code}`;
  }
  if(this.data['typeCode'] == '972' ){
    apiUrl = `api/updateCodes972/${this.library.code}`;
  }
  if(this.data['typeCode'] == '905' ){
    apiUrl = `api/updateCodes905/${this.library.code}`;
  }
  this.codes985Service.createCodes985(apiUrl, this.codes985)
    .subscribe(res => {
        if(res != null){
          this.library = res as Library;
          this.stopSubscriber = true;
          this.librariesService.setPassObject(this.library);
          
        }
        
    },
    (error => {
      this.errorService.dialogConfig = this.dialogConfig;
      this.errorService.handleError(error);
    })
  )
  //this.librariesService.unsuscribe();
}

onNoClick(): void {
  this.dialogRef.close();
}

}
