import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { User } from '../../_interface/user.model';
import { LoginService } from '../../services/login.service';
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  private unSubscri: Subscription;
  
  @Output() public sidenavToggle = new EventEmitter();
  public user : User;
  
  constructor(private loginService: LoginService,
    private errorService: ErrorHandlerService,
    @Inject(DOCUMENT) private document: any) { }

ngOnInit() {
  this.getUserLogged();
}

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }


  public getUserLogged = () => {
    //this.loginService.getData(`api/findUser`).subscribe(res => {
      this.unSubscri = this.loginService.passObject.subscribe(res => {
      if(res != null){
        this.user = res as User;
      }
     
    },
    (error) => {
      this.errorService.handleError(error);
    })
  }
  

  ngOnDestroy() {
    if(this.unSubscri  != null){
      this.unSubscri.unsubscribe();
    }
  }

  goToZendesk(): void {
    this.document.location.href = 'https://coordvd.zendesk.com/hc/fr';
  }
}
