import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { NotFoundComponent } from '../error-pages/not-found/not-found.component';
import { ServerErrorComponent } from '../error-pages/server-error/server-error.component';
import { AccessDeniedComponent } from '../error-pages/access-denied/access-denied.component';
 

const librariesRoutes: Routes = [
  { path: 'home', redirectTo: '/libraries/listlibraries', pathMatch: 'full'},
  { path: 'libraries', loadChildren: "./../libraries/libraries.module#LibrariesModule" },
  { path: '404', component: NotFoundComponent }, 
  { path: '403403', component: AccessDeniedComponent }, 
  { path: '500', component: ServerErrorComponent },
  { path: '', redirectTo: '/libraries/listlibraries', pathMatch: 'full' },
  { path: '**', redirectTo: '/404', pathMatch: 'full'}
];

 
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(librariesRoutes, { useHash: true })
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class RoutingModule { }