import { Component, OnInit, Inject } from '@angular/core';
import { Library } from '../../../_interface/library.model';
import { Personne } from '../../../_interface/personne.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LibrariesService } from '../../../services/libraries/libraries.service';
import { PersonneService } from 'src/app/services/personne/personne.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ErrorHandlerService } from '../../error-handler.service';
import { Location} from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-dialog-personne',
  templateUrl: './add-dialog-personne.component.html',
  styleUrls: ['./add-dialog-personne.component.css']
})
export class AddDialogPersonneComponent implements OnInit {

  private unSubscri: Subscription;
  private library:Library;
  private personne = <Personne>{} ;
  public personneForm: FormGroup;
  private dialogConfig;
  private stopSubscriber: boolean;
  public  buttonSave:string;
  public  title:string;
  
  
  constructor(private librariesService: LibrariesService,
    private personneService: PersonneService,
    private dialogRef: MatDialogRef<AddDialogPersonneComponent>,
    //private dialog: MatDialog,
    private errorService: ErrorHandlerService,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    }


    ngOnInit() {
      var codedisabled: boolean;
      if(this.data['personnes'] !== null && this.data['personnes'] !== undefined){
        codedisabled = false;
        this.buttonSave = 'Modifier';
        this.title ='Modifier les informations';
      }else{
        codedisabled = false;
        this.buttonSave = 'Ajouter';
        this.title ='Ajouter un nouveau responsable';
      }
      this.personneForm = new FormGroup({ 
        id:new FormControl({ value: '', disabled: codedisabled },[]),
        nom: new FormControl({ value: '', disabled: codedisabled },[Validators.required]),
        email: new FormControl({ value: '', disabled: codedisabled },[Validators.required, Validators.email]),
        telephone: new FormControl('', [Validators.required]),
        remarques: new FormControl('')
    })
    if(this.data['personnes'] !== null && this.data['personnes']!== undefined){
      this.personneForm.patchValue(this.data['personnes']);
    }
   
    this.dialogConfig = {
      height: '400px',
      width: '400px',
      disableClose: true,
      data: { }
    }
  
  }
  
  ngOnDestroy() {
    if(this.unSubscri  != null){
      this.unSubscri.unsubscribe();
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.personneForm.controls; }

  public createPersonne = (personneForm) => {

    this.stopSubscriber = false;
    if (this.personneForm.valid) {
      this.unSubscri = this.librariesService.passObject.subscribe(res => {
          if(res !== null){
            this.library  = res as Library;
            if(this.data['personnes'] !== null && this.data['personnes']!== undefined){
              if(!this.stopSubscriber){
                this.executeUpdatePersonne(personneForm.getRawValue());
              }
               
            }else{
              if(!this.stopSubscriber){
                this.executeCreatePersonne(personneForm.getRawValue());
              }
          }
          } 
      })
    }
  }

  private executeCreatePersonne = (personneForm) => {

    this.personne.nom        = this.personneForm.value.nom;
    this.personne.email      = this.personneForm.value.email;
    this.personne.telephone  = this.personneForm.value.telephone;
    this.personne.remarques  = this.personneForm.value.remarques;
    var apiUrl = `api/createPersonne/${this.library.code}`;
    
    this.personneService.createPersonne(apiUrl, this.personne)
      .subscribe(res => {
          if(res != null){
            this.library = res as Library;
            this.stopSubscriber = true;
            this.librariesService.setPassObject(this.library);
          }
          
      },
      (error => {
        this.errorService.dialogConfig = this.dialogConfig;
        this.errorService.handleError(error);
      })
    )
  }
  
  private executeUpdatePersonne = (personneForm) => {
  
    this.personne.id         = this.personneForm.value.id;
    this.personne.nom        = this.personneForm.value.nom;
    this.personne.email      = this.personneForm.value.email;
    this.personne.telephone  = this.personneForm.value.telephone;
    this.personne.remarques  = this.personneForm.value.remarques;
    var apiUrl = `api/updatePersonne/${this.library.code}`;
    
    this.personneService.createPersonne(apiUrl, this.personne)
      .subscribe(res => {
          if(res != null){
            this.library = res as Library;
            this.stopSubscriber = true;
            this.librariesService.setPassObject(this.library);
          }
          
      },
      (error => {
        this.errorService.dialogConfig = this.dialogConfig;
        this.errorService.handleError(error);
      })
    )
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

}
