import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  private passObjectBS   = new BehaviorSubject <any> (null);
  public passObject    = this.passObjectBS.asObservable();

  constructor(private http: HttpClient) { }

  public setPassObject(obj : any){
    this.passObjectBS.next(obj);
  }

  public unsuscribe(){
    this.passObjectBS.unsubscribe;
  }

  public getData(route: string) {
    return this.http.get(this.createCompleteRoute(route, environment.urlAddress));
  }
  
  public createCodes905(route: string, body){
    return this.http.post(this.createCompleteRoute(route, environment.urlAddress), body, this.generateHeaders());
  }

  private generateHeaders() {
    return {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }
  }

  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}/${route}`;
  }
}
