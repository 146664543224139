import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Library } from 'src/app/_interface/library.model';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LibrariesService } from 'src/app/services/libraries/libraries.service';
import { CodesDto } from 'src/app/_interface/codesDto.model';
import { takeUntil, take } from 'rxjs/operators';
import { ErrorHandlerService } from '../../error-handler.service';
import { Codes985Service } from 'src/app/services/codes985/codes985.service';
import { Codes985 } from 'src/app/_interface/codes985.model';
import { Codes972 } from 'src/app/_interface/codes972.model';
import { Codes905 } from 'src/app/_interface/codes905.model';

@Component({
  selector: 'app-add-dialog-all-codes',
  templateUrl: './add-dialog-all-codes.component.html',
  styleUrls: ['./add-dialog-all-codes.component.css']
})
export class AddDialogAllCodesComponent implements OnInit {

  public codesDtoForm: FormGroup;
  private dialogConfig;
  public code972:boolean    = false;
  private stopSubscriber: boolean;
  public  buttonSave:string;
  public  title:string;
  

  private libraries: Library[];
  /** control for the selected library */
  public libraryCtrl: FormControl;

   /** control for the MatSelect filter keyword */
  public libraryFilterCtrl: FormControl = new FormControl();

  /** list of libraries filtered by search keyword */
  public filteredLibraries: ReplaySubject<Library[]> = new ReplaySubject<Library[]>(1);

  @ViewChild('singleSelect', {static: false}) singleSelect: MatSelect; 

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private librariesService: LibrariesService,
              private codes985Service: Codes985Service,
              private errorService: ErrorHandlerService,
              private dialogRef: MatDialogRef<AddDialogAllCodesComponent>) { 
    
  }

  ngOnInit() {
    var codedisabled: boolean;
    if(this.data['codesDto'] !== null && this.data['codesDto'] !== undefined){
      codedisabled = true;
      this.libraryCtrl = new FormControl({value: '', disabled: true}, [Validators.required]);
    }else{
      codedisabled = false;
      this.libraryCtrl = new FormControl({value: '', disabled: false}, [Validators.required]);
    }

    if(this.data['typeCode'] === '972'){
      this.code972 = true;
      this.codesDtoForm = new FormGroup({ 
        codeCode: new FormControl({ value: '', disabled: codedisabled },[Validators.required])
        /*descriptionCode: new FormControl({value:'-', disabled: true})*/
       })
    }else{
      this.code972 = false;
      this.codesDtoForm = new FormGroup({ 
        codeCode: new FormControl({ value: '', disabled: codedisabled },[Validators.required]),
        descriptionCode: new FormControl('', [Validators.required])
       })
    }
   
    this.getAllLibraries();
  
    if(this.data['codesDto'] !== null && this.data['codesDto'] !== undefined){
        codedisabled = true;
        this.buttonSave = 'Modifier';
        this.title ='Modifier le code '+this.data['typeCode'];
    }else{
        codedisabled = false;
        this.buttonSave = 'Ajouter';
        this.title ='Ajouter un nouveau code '+this.data['typeCode'];
    }
    
    this.dialogConfig = {
      height: '400px',
      width: '400px',
      disableClose: true,
      data: { }
    }


  }

  ngAfterViewInit() {
    this.setInitialValue();
  }
  
  ngOnDestroy() {
    //this.librariesService.unsuscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private filterLibraries() {
    if (!this.libraries) {
      return;
    }
    // get the search keyword
    let search = this.libraryFilterCtrl.value;
    if (!search) {
      this.filteredLibraries.next(this.libraries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the libraries
    this.filteredLibraries.next(
      this.libraries.filter(library => library.nom.toLowerCase().indexOf(search) > -1)
    );
  }

    /**
   * Sets the initial value after the filteredLibraries are loaded initially
   */
  private setInitialValue() {
    this.filteredLibraries
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {          
        // setting the compareWith property to a comparison function 
        // triggers initializing the selection according to the initial value of 
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredLibrarieLibrais are loaded initially 
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: Library, b: Library) => a.code === b.code;
      });
  }

  public getAllLibraries = () => {
    this.librariesService.getData('api/getAllLibraryDB')
    .subscribe(res => {
      this.libraries = res as Library[];
      if(this.libraries !== undefined){

      let codesDto = this.data['codesDto'] as CodesDto;
      if(codesDto !== null && codesDto!== undefined){

        /*this.codesDtoForm.controls['codeLibrary'].setValue(codesDto.codeLibrary);
        this.codesDtoForm.controls['nomLibrary'].setValue(codesDto.nomLibrary);*/
        this.codesDtoForm.controls['codeCode'].setValue(codesDto.codeCode);
        this.codesDtoForm.controls['descriptionCode'].setValue(codesDto.descriptionCode);
        
        // set initial selection
        this.libraryCtrl.setValue(this.getlibByCode(codesDto.codeLibrary));
      }
  
      // load the initial library list
      this.filteredLibraries.next(this.libraries.slice());
  
      // listen for search field value changes
      this.libraryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterLibraries();
      });
    }
  },
    (error) => {
      this.errorService.handleError(error);
    })
    
  }

  private getlibByCode(codeLibrary: String){
    for (let entry of this.libraries) {
        if(entry.code === codeLibrary){
          return entry;
        }
    }
  }

  public createcodesDto = (codesDtoForm, libCode) => {
    
    this.stopSubscriber = false;
    if (this.codesDtoForm.valid) {
      if(this.data['codesDto'] !== null && this.data['codesDto']!== undefined){
        if(!this.stopSubscriber){
          this.executeUpdateCodesDto(codesDtoForm, libCode);
        }
      }else{
        if(!this.stopSubscriber){
          this.executeCreateCodesDto(codesDtoForm, libCode);
        }
      }
    }
  }

  private executeCreateCodesDto = (codesDtoForm, libCode) => {

  var apiUrl :string;
  var objCode:any;
  if(this.data['typeCode'] == '985' ){
    objCode = <Codes985>{} ;
    apiUrl = `api/createCodes985/`+libCode;
  } else if(this.data['typeCode'] == '972' ){
    objCode = <Codes972>{} ;
    apiUrl = `api/createCodes972/`+libCode;
  } else if(this.data['typeCode'] == '905' ){
    objCode = <Codes905>{} ;
    apiUrl = `api/createCodes905/`+libCode;
  }

  objCode.code         = codesDtoForm.get('codeCode').value;
  if(this.data['typeCode'] == '972' ){
    objCode.description  = '-';
  }else{
    objCode.description  = codesDtoForm.get('descriptionCode').value;
  }
  
  this.codes985Service.createCodes985(apiUrl, objCode)
      .subscribe(res => {
          if(res != null){
            this.stopSubscriber = true;
          }
          
      },
      (error => {
        this.errorService.dialogConfig = this.dialogConfig;
        this.errorService.handleError(error);
      })
    )
  }
  
  private executeUpdateCodesDto = (codesDtoForm, libCode) => {
  
    var apiUrl :string;
    var objCode:any;
    if(this.data['typeCode'] == '985' ){
      objCode = <Codes985>{} ;
      apiUrl = `api/updateCodes985/`+libCode;
    } else if(this.data['typeCode'] == '972' ){
      objCode = <Codes972>{} ;
      apiUrl = `api/updateCodes972/`+libCode;
    } else if(this.data['typeCode'] == '905' ){
      objCode = <Codes905>{} ;
      apiUrl = `api/updateCodes905/`+libCode;
    }

    objCode.code         = codesDtoForm.get('codeCode').value;
    if(this.data['typeCode'] == '972' ){
      objCode.description  = '-';
    }else{
      objCode.description  = codesDtoForm.get('descriptionCode').value;
    }
  
    this.codes985Service.createCodes985(apiUrl, objCode)
      .subscribe(res => {
          if(res != null){
            this.stopSubscriber = true;
          }
      },
      (error => {
        this.errorService.dialogConfig = this.dialogConfig;
        this.errorService.handleError(error);
      })
    )
  }

}
