import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Library } from '../../../_interface/library.model';
import { Personne } from '../../../_interface/personne.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LibrariesService } from '../../../services/libraries/libraries.service';
import { PersonneService } from 'src/app/services/personne/personne.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelect } from '@angular/material';
import { ErrorHandlerService } from '../../error-handler.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { ResponsableDto } from '../../../_interface/responsableDto.model';

@Component({
  selector: 'app-add-dialog-all-responsable',
  templateUrl: './add-dialog-all-responsable.component.html',
  styleUrls: ['./add-dialog-all-responsable.component.css']
})
export class AddDialogAllResponsableComponent implements OnInit {

  //private library:Library;
  //private responsableDto = <ResponsableDto>{} ;
  public responsableDtoForm: FormGroup;
  private dialogConfig;
  private stopSubscriber: boolean;
  public  buttonSave:string;
  public  title:string;
  

  private libraries: Library[];
  /** control for the selected library */
  public libraryCtrl: FormControl;

   /** control for the MatSelect filter keyword */
  public libraryFilterCtrl: FormControl = new FormControl();

  /** list of libraries filtered by search keyword */
  public filteredLibraries: ReplaySubject<Library[]> = new ReplaySubject<Library[]>(1);

  @ViewChild('singleSelect', {static: false}) singleSelect: MatSelect; 

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  
  constructor(
    private librariesService: LibrariesService,
    private personneService: PersonneService,
    private dialogRef: MatDialogRef<AddDialogAllResponsableComponent>,
    private errorService: ErrorHandlerService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    }


    ngOnInit() {

      if(this.data['personnes'] !== null && this.data['personnes'] !== undefined){
        this.libraryCtrl = new FormControl({value: '', disabled: true}, [Validators.required]);
      }else{
        this.libraryCtrl = new FormControl({value: '', disabled: false}, [Validators.required]);
      }
      this.getAllLibraries();
      var codedisabled: boolean;
      if(this.data['personnes'] !== null && this.data['personnes'] !== undefined){
        codedisabled = false;
        this.buttonSave = 'Modifier';
        this.title ='Modifier les informations';
      }else{
        codedisabled = false;
        this.buttonSave = 'Ajouter';
        this.title ='Ajouter un nouveau responsable';
      }
      this.responsableDtoForm = new FormGroup({ 
        idResp:new FormControl({ value: '', disabled: codedisabled },[]),
        nomResp: new FormControl({ value: '', disabled: codedisabled },[Validators.required]),
        emailResp: new FormControl({ value: '', disabled: codedisabled },[Validators.required, Validators.email]),
        teleResp: new FormControl('', [Validators.required]),
        remaResp: new FormControl(''),
    })

    this.dialogConfig = {
      height: '400px',
      width: '400px',
      disableClose: true,
      data: { }
    }

  
     
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }
  
  ngOnDestroy() {
    //this.librariesService.unsuscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // convenience getter for easy access to form fields
  

  public createPersonne = (responsableDtoForm, libCode) => {
    this.stopSubscriber = false;
    if (this.responsableDtoForm.valid) {
      if(this.data['personnes'] !== null && this.data['personnes']!== undefined){
        if(!this.stopSubscriber){
          this.executeUpdateResponsableDto(responsableDtoForm.getRawValue(), libCode);
        }
      }else{
        if(!this.stopSubscriber){
          this.executeCreateResponsableDto(responsableDtoForm.getRawValue(), libCode);
        }
      }
    }
  }

  private executeCreateResponsableDto = (responsableDtoForm, libCode) => {

    let personne = <Personne>{} ;
    personne.nom         = this.responsableDtoForm.value.nomResp;
    personne.email       = this.responsableDtoForm.value.emailResp;
    personne.telephone   = this.responsableDtoForm.value.teleResp;
    personne.remarques   = this.responsableDtoForm.value.remaResp;
    var apiUrl = `api/createPersonne/`+libCode;
    this.personneService.createPersonne(apiUrl, personne)
      .subscribe(res => {
          if(res != null){
            this.stopSubscriber = true;
          }
          
      },
      (error => {
        this.errorService.dialogConfig = this.dialogConfig;
        this.errorService.handleError(error);
      })
    )
  }
  
  private executeUpdateResponsableDto = (responsableDtoForm, libCode) => {
  
    let personne = <Personne>{} ;
    personne.id         = this.responsableDtoForm.value.idResp;
    personne.nom        = this.responsableDtoForm.value.nomResp;
    personne.email      = this.responsableDtoForm.value.emailResp;
    personne.telephone  = this.responsableDtoForm.value.teleResp;
    personne.remarques  = this.responsableDtoForm.value.remaResp;
    var apiUrl = `api/updatePersonne/`+libCode;
    
    this.personneService.createPersonne(apiUrl, personne)
      .subscribe(res => {
          if(res != null){
            this.stopSubscriber = true;
          }
          
      },
      (error => {
        this.errorService.dialogConfig = this.dialogConfig;
        this.errorService.handleError(error);
      })
    )
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  private filterLibraries() {
    if (!this.libraries) {
      return;
    }
    // get the search keyword
    let search = this.libraryFilterCtrl.value;
    if (!search) {
      this.filteredLibraries.next(this.libraries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the libraries
    this.filteredLibraries.next(
      this.libraries.filter(library => library.nom.toLowerCase().indexOf(search) > -1)
    );
  }

    /**
   * Sets the initial value after the filteredLibraries are loaded initially
   */
  private setInitialValue() {
    this.filteredLibraries
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {          
        // setting the compareWith property to a comparison function 
        // triggers initializing the selection according to the initial value of 
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredLibrarieLibrais are loaded initially 
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: Library, b: Library) => a.code === b.code;
      });
  }

  public getAllLibraries = () => {
    this.librariesService.getData('api/getAllLibraryDB')
    .subscribe(res => {
      this.libraries = res as Library[];
      if(this.libraries !== undefined){

      let respDto = this.data['personnes'] as ResponsableDto;
      if(respDto !== null && respDto!== undefined){

        //this.personne.id         = this.personneForm.value.id;
        this.responsableDtoForm.controls['idResp'].setValue(respDto.idResp);
        this.responsableDtoForm.controls['nomResp'].setValue(respDto.nomResp);
        this.responsableDtoForm.controls['emailResp'].setValue(respDto.emailResp);
        this.responsableDtoForm.controls['teleResp'].setValue(respDto.teleResp);
        this.responsableDtoForm.controls['remaResp'].setValue(respDto.remaResp);
        
        // set initial selection
        this.libraryCtrl.setValue(this.getlibByCode(respDto.codeLibrary));
      }
  
      // load the initial library list
      this.filteredLibraries.next(this.libraries.slice());
  
      // listen for search field value changes
      this.libraryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterLibraries();
      });
    }
  },
    (error) => {
      this.errorService.handleError(error);
    })
    
  }

  private getlibByCode(codeLibrary: String){
    for (let entry of this.libraries) {
        if(entry.code === codeLibrary){
          return entry;
        }
    }
  }

}

