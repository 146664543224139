import { Component, OnInit, Inject } from '@angular/core';
import { Library } from 'src/app/_interface/library.model';
import { Primo } from 'src/app/_interface/primo.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PrimoService } from 'src/app/services/primo/primo.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ErrorHandlerService } from '../../error-handler.service';
import { Location} from '@angular/common';
import { LibrariesService } from 'src/app/services/libraries/libraries.service';
import { LibraryDBPrimo } from 'src/app/_interface/libraryDBPrimo.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-dialog-primo',
  templateUrl: './add-dialog-primo.component.html',
  styleUrls: ['./add-dialog-primo.component.css']
})
export class AddDialogPrimoComponent implements OnInit {

  private unSubscri: Subscription;
  private library:Library;
  private libraryDBPrimo = <LibraryDBPrimo>{} ;
  public primoForm: FormGroup;
  private dialogConfig;
  private stopSubscriber: boolean;
  public  buttonSave:string;
  public  title:string;

  constructor(private librariesService: LibrariesService,
    private primoService: PrimoService,
    private dialogRef: MatDialogRef<AddDialogPrimoComponent>,
    private errorService: ErrorHandlerService,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    }


  ngOnInit() {

    var codedisabled: boolean;
    if(this.data['libraryDBPrimo'] !== null && this.data['libraryDBPrimo'] !== undefined){
      codedisabled = true;
      this.buttonSave = 'Modifier';
      this.title ='Modifier les informations';
    }else{
      codedisabled = false;
      this.buttonSave = 'Ajouter';
      this.title ='Ajouter';
    }
    this.primoForm = new FormGroup({ 
    
      codeVue:new FormControl({ value: '', disabled: codedisabled },[Validators.required]),
      urlPrimo: new FormControl('', [Validators.required]),
      codeBibPrimo: new FormControl('', [Validators.required]),
      nomBibPrimo: new FormControl('', [Validators.required]),
      urlCourte: new FormControl('', [Validators.required])
      
  })
  if(this.data['libraryDBPrimo'] !== null && this.data['libraryDBPrimo']!== undefined){
    this.primoForm.patchValue(this.data['libraryDBPrimo']);
  }
 
  this.dialogConfig = {
    height: '400px',
    width: '400px',
    disableClose: true,
    data: { }
  }
}

ngOnDestroy() {
  if(this.unSubscri  != null){
    this.unSubscri.unsubscribe();
  }
}

public createLibraryDBPrimo = (primoForm) => {

  this.stopSubscriber = false;
  if (this.primoForm.valid) {
    this.unSubscri = this.librariesService.passObject.subscribe(res => {
        if(res !== null){
          this.library  = res as Library;
          if(this.data['libraryDBPrimo'] !== null && this.data['libraryDBPrimo']!== undefined){
            if(!this.stopSubscriber){
              this.executeUpdateLibraryDBPrimo(primoForm.getRawValue());
            }
             
          }else{
            if(!this.stopSubscriber){
              this.executeCreateLibraryDBPrimo( );
            }
        }
        } 
    })
  }
}

private executeCreateLibraryDBPrimo = () => {
  
  this.libraryDBPrimo.codeVue          = this.primoForm.value.codeVue;
  this.libraryDBPrimo.urlPrimo         = this.primoForm.value.urlPrimo;
  this.libraryDBPrimo.codeBibPrimo     = this.primoForm.value.codeBibPrimo;
  this.libraryDBPrimo.nomBibPrimo      = this.primoForm.value.nomBibPrimo;
  this.libraryDBPrimo.urlCourte        = this.primoForm.value.urlCourte;
 
  var apiUrl = `api/createPrimo/${this.library.code}`;
  
  this.primoService.createPrimo(apiUrl, this.libraryDBPrimo)
    .subscribe(res => {
        if(res != null){
          this.library = res as Library;
          this.stopSubscriber = true;
          this.librariesService.setPassObject(this.library);
        }
        
    },
    (error => {
      this.errorService.dialogConfig = this.dialogConfig;
      this.errorService.handleError(error);
    })
  )
}

private executeUpdateLibraryDBPrimo = (primoForm) => {
  this.libraryDBPrimo.codeVue          = primoForm.codeVue;
  this.libraryDBPrimo.urlPrimo         = primoForm.urlPrimo;
  this.libraryDBPrimo.codeBibPrimo     = primoForm.codeBibPrimo;
  this.libraryDBPrimo.nomBibPrimo      = primoForm.nomBibPrimo;
  this.libraryDBPrimo.urlCourte        = primoForm.urlCourte;
  var apiUrl = `api/updatePrimo/${this.library.code}`;
  
  this.primoService.createPrimo(apiUrl, this.libraryDBPrimo)
    .subscribe(res => {
        if(res != null){
          this.library = res as Library;
          this.stopSubscriber = true;
          this.librariesService.setPassObject(this.library);
        }
        
    },
    (error => {
      this.errorService.dialogConfig = this.dialogConfig;
      this.errorService.handleError(error);
    })
  )
}

onNoClick(): void {
  this.dialogRef.close();
}


}
