import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SuccessDialogComponent } from './dialogs/success-dialog/success-dialog.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { AddDialogComponent } from './dialogs/add-dialog/add-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AddDialogPersonneComponent } from './dialogs/add-dialog-personne/add-dialog-personne.component';
import { AddDialogPrimoComponent } from './dialogs/add-dialog-primo/add-dialog-primo.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { AddDialogAllResponsableComponent } from './dialogs/add-dialog-all-responsable/add-dialog-all-responsable.component';
import { AddDialogAllCodesComponent } from './dialogs/add-dialog-all-codes/add-dialog-all-codes.component';

//import { MatSelectSearchComponent } from './mat-select-search/mat-select-search.component';

@NgModule({
  declarations: [
  SuccessDialogComponent,
  ErrorDialogComponent,
  AddDialogComponent,
  AddDialogPersonneComponent,
  AddDialogPrimoComponent,
  ConfirmDialogComponent,
  AddDialogAllResponsableComponent,
  AddDialogAllCodesComponent
 // MatSelectSearchComponent
],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    SuccessDialogComponent,
    ErrorDialogComponent
  ],
  /* we need to place the dialog components inside the entryComponents array because we are not going to */
  /* use routing nor app selector to call these components. We are going to use them as a template reference for the  */
  /* dialog’s open() function and thus the need for the entryComponents array: */ 
  entryComponents: [
    SuccessDialogComponent,
    ErrorDialogComponent,
    AddDialogComponent,
    AddDialogPersonneComponent,
    AddDialogPrimoComponent,
    ConfirmDialogComponent,
    AddDialogAllResponsableComponent,
    AddDialogAllCodesComponent
  ]
})
export class SharedModule { }
